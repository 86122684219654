<template>
  <div class="navbar-collapse d-xl-none">
    <b-navbar
      class="navbar-top-sm justify-content-between"
      toggleable="md"
      type="dark"
    >
      <b-navbar-brand href="#">
        <ButtonPiquassoHome />
      </b-navbar-brand>
      <div class="d-flex flex-row">
        <UserAccountDropdown class="d-flex d-md-none mr-4" />
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/projects"
                class="nav-link"
                :event="isAuthenticated ? 'click' : ''"
                >My Projects</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/published" class="nav-link"
                >Published</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="#" class="nav-link" disabled
                >Documentation</router-link
              >
            </li>
            <UserAccountDropdown class="d-none d-md-flex" />
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import ButtonPiquassoHome from '../atomic/a-button-piquasso-home'
import UserAccountDropdown from '@/components/accountmanagement/user-account-dropdown'
import { mapGetters } from 'vuex'

export default {
  name: 'NavbarTopSm',
  components: {
    ButtonPiquassoHome,
    UserAccountDropdown,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>

<style scoped>
.navbar-top-sm {
  height: 56px;
}

.nav-item {
  padding: 0 0.5rem;
}
.nav-link {
  color: var(--pq-font-color-shade4);
  font-size: 0.9rem;
}

.nav-item .router-link-exact-active {
  color: var(--pq-font-color-shade1);
}

.nav-link-disabled {
  color: #4b5665;
}

@media (max-width: 991.98px) {
  .nav-item {
    padding: 0.5rem 0.25rem;
  }
}

@media (max-width: 767.98px) {
  #nav-collapse {
    position: fixed;
    width: 100%;
    left: 0;
    top: 3rem;
    z-index: 2;
    background-color: var(--pq-blue-shade1);
    box-shadow: 0 15px 15px 2px rgb(0 0 0 / 20%);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }
}
</style>
