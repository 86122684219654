<template>
  <div id="root-div">
    <div class="container-fluid">
      <div class="row project-bar-row">
        <div
          class="col-lg-12 d-none d-xl-block project-bar-col p-lr-30"
          :class="{
            'border-0':
              this.$router.currentRoute.name === 'composer' ||
              this.$router.currentRoute.name === 'published',
          }"
        >
          <VProjectTabs />
        </div>
        <div class="col-lg-12 d-flex d-xl-none navbar-top-sm-col">
          <NavbarTopSm />
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import VProjectTabs from '@/components/navbars/v-project-tabs.vue'
import NavbarTopSm from '@/components/navbars/navbar-top-sm'

export default {
  name: 'MainLayout',
  components: {
    VProjectTabs,
    NavbarTopSm,
  },
}
</script>

<style>
#root-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  background-color: var(--pq-blue-shade1);
}

.project-bar-row {
  height: 40px;
}

.project-bar-col,
.navbar-top-sm-col {
  border-bottom: 1px solid var(--pq-color-border-dark);
}

.project-bar-col {
  position: fixed;
  z-index: 999;
  background-color: var(--pq-blue-shade1);
}

@media (max-width: 1199.98px) {
  .project-bar-row {
    height: 56px;
  }
}
</style>
