<template>
  <svg
    class="tabs-close-icon"
    v-bind:width="width"
    v-bind:height="height"
    v-bind:stroke="stroke"
  >
    <path d="M 4 4 L 12 12 M 12 4 L 4 12"></path>
  </svg>
</template>

<script>
export default {
  name: 'a-icon-tab-close',
  props: {
    width: {
      default: 16,
      type: Number,
    },
    height: {
      default: 16,
      type: Number,
    },
    stroke: {
      default: '#555A69',
      type: String,
    },
  },
}
</script>

<style scoped>
.tabs-close-icon {
  display: block;
}
</style>
