<template>
  <div class="project-bar">
    <div class="tabs-opened">
      <ButtonPiquassoHome />
      <div class="d-flex flex-row align-items-center">
        <template v-for="(item, index) in this.getOpenedProjects">
          <TabsItem
            :key="index"
            :index="index"
            :projectId="item.id"
            :projectType="item.visibility"
          />
          <div :key="'d-' + index" class="tabs-divider"></div>
        </template>
      </div>
    </div>
    <UserAccountDropdown />
    <div v-if="!this.isAuthenticated" class="d-flex flex-row signup-panel">
      <div class="mr-4 d-flex">
        <button class="signup-panel-btn signin-btn" @click="showSignupModal">
          Sign in
        </button>
      </div>
      <div>
        <button class="signup-panel-btn signup-btn" @click="showSignupModal">
          Sign Up
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { LOGOUT } from '@/store/actions.type'
import { ModalBus } from '@/common/eventBus'
import TabsItem from '@/components/m-tabs-item'
import SignupModal from '@/components/modals/signup-modal'
import ButtonPiquassoHome from '@/components/atomic/a-button-piquasso-home'
import UserAccountDropdown from '@/components/accountmanagement/user-account-dropdown'

export default Vue.extend({
  components: {
    TabsItem,
    ButtonPiquassoHome,
    UserAccountDropdown,
  },
  methods: {
    showSignupModal() {
      ModalBus.$emit('open', {
        component: SignupModal,
        title: 'Signin to Piquasso',
      })
    },
    async logout() {
      await this.$store.dispatch(LOGOUT)
    },
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'currentUser',
      'getOpenedProjects',
      'getCurrentProject',
    ]),
  },
})
</script>

<style>
.project-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tabs-opened {
  position: relative;
  color: var(--pq-font-color-shade3);
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.tabs-divider {
  height: 20px;
  width: 1px;
  background-color: var(--pq-color-border-dark);
}

.tabs-active-left {
  border-radius: 0 0 15px 0;
}

.tabs-active-right {
  border-radius: 0 0 0 15px;
}

.user-panel:hover p {
  color: var(--pq-font-color-shade1);
}

.dropdown-toggle btn-primary {
  background-color: transparent;
  padding: 0;
}

.signup-panel {
  font-size: 0.85rem;
}

.signin-btn {
  background-color: transparent;
}

.signup-btn {
  height: 36px;
  padding: 0 1rem;
  background-color: transparent;
  border-radius: 2rem;
}

.signup-panel-btn {
  color: #aaa;
  border: none;
}

.modal-dialog {
  margin-top: 5%;
}

.modal-header {
  border-bottom: 0;
}

.modal-header button {
  color: #fff;
}

.modal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #ddd;
  width: 100%;
  pointer-events: auto;
  background-color: #181d24;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: hsl(215deg 20% 12% / 80%);
  box-shadow: 0 0 25px 3px rgb(0 0 0 / 20%);
}

/* SOCIAL MEDIA BUTTON */
.fa {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 100%;
  padding: 1rem;
  margin-right: 1rem;
}

.fa:hover {
  opacity: 0.7;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-github {
  background: #24292e;
  color: white;
}
</style>
