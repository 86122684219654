<template>
  <div class="tabs-item">
    <div
      class="tabs-main"
      v-bind:class="[
        projectIsActive ? 'tabs-active' : '',
        projectIsSaved ? '' : 'unsaved',
      ]"
      @click="changeCurrentProject"
    >
      <span class="tab-name">{{ projectName }}</span>
    </div>
    <div class="tabs-close" @click="closeCurrentProject">
      <IconTabClose></IconTabClose>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import IconTabClose from './atomic/a-icon-tab-close'
import { mapGetters } from 'vuex'
import { CLOSE_PROJECT } from '@/store/actions.type'

export default Vue.extend({
  name: 'TabsItem',
  components: {
    IconTabClose,
  },
  props: ['index', 'projectId', 'projectType'],
  methods: {
    changeCurrentProject() {
      const route = this.projectType === 'private' ? 'composer' : 'published'
      this.$router.push({ name: route, params: { projectId: this.projectId } })
    },

    closeCurrentProject() {
      const thisProject = this.getProjectById(this.projectId)
      this.$store.dispatch(CLOSE_PROJECT, thisProject)

      if (this.getOpenedProjects.length === 0) {
        this.$router.push({ name: 'dashboard-home' })

      } else {
        const route =
          this.getCurrentProject.visibility === 'private'
            ? 'composer'
            : 'published'

        this.$router.push({
          name: route,
          params: { projectId: this.getCurrentProject.id }
        })
      }
    },
  },
  computed: {
    ...mapGetters(['getOpenedProjects', 'getCurrentProject', 'getProjectById']),
    projectIsActive: function () {
      return this.getOpenedProjects[this.index]?.isActive
    },
    projectIsSaved: function () {
      return this.getOpenedProjects[this.index]?.isSaved
    },
    projectName: function () {
      return this.getOpenedProjects[this.index]?.name
    },
  },
})
</script>

<style scoped>
.tabs-item {
  position: relative;
  width: 175px;
  height: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabs-main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tabs-main:hover:not(.tabs-active) {
  color: var(--pq-font-color-shade1);
  background-color: #212c4a;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tabs-main > span {
  margin-bottom: 5px;
}

.tab-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs-close {
  position: absolute;
  display: none;
  top: 45%;
  right: 0;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.tabs-close:hover {
  background-color: var(--pq-blue-shade1);
}

.tabs-close:hover,
.tabs-main:hover ~ .tabs-close {
  display: block;
}

.tabs-main:not(.tabs-active) ~ .tabs-close:hover {
  background-color: var(--pq-blue-shade2);
}

.tabs-active {
  color: var(--pq-font-color-shade1);
  background-color: var(--pq-blue-shade2);
}

.unsaved::before {
  content: '*';
}
</style>
