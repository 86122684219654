<template>
  <router-link
    to="/"
    @click.native="changeToDashboard()"
    class="button piquasso-home-button mr-4"
    :class="{ active: this.isActive }"
  >
    <img
      src="../../assets/images/icons/piquasso-branding-name.png"
      alt="Piquasso branding"
    />
  </router-link>
</template>

<script>
import { SET_DEACTIVATED_PROJECT } from '@/store/mutations.type'
export default {
  name: 'a-button-piquasso-home',
  data() {
    return {
      isActive: true,
    }
  },
  methods: {
    changeToDashboard() {
      this.$store.commit(SET_DEACTIVATED_PROJECT)
    },
  },
  watch: {
    $route() {
      if (this.$route.path === '/' || this.$route.path.includes('/projects')) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
  },
}
</script>

<style scoped>
.piquasso-home-button {
  color: #ddd;
  font-size: 1rem;
  font-family: 'Nexa Light', serif;
  display: flex;
  height: 40px;
  padding: 0;
  letter-spacing: 3px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.75rem 0 0;
}

.piquasso-home-button img {
  max-height: 40px;
  max-width: 105px;
}

.active {
  background-color: var(--pq-blue-shade1) !important;
}

@media (max-width: 1199.98px) {
  .piquasso-home-button {
    height: auto;
  }
}
</style>
