<template>
  <div class="d-flex text-center justify-content-evenly">
    <button class="fa fa-google" @click="this.loginWithGoogle">
      <font-awesome-icon :icon="['fab', 'google']" size="lg" />
    </button>
    <button class="btn fa fa-github" @click="this.loginWithGithub">
      <font-awesome-icon :icon="['fab', 'github']" size="lg" />
    </button>
  </div>
</template>

<script>
import { LOGIN } from '@/store/actions.type'

export default {
  name: 'SignupModal',
  methods: {
    loginWithGoogle() {
      window.open(`${process.env.VUE_APP_HTTP_URL}/auth/google`, '_blank')
      window.addEventListener('message', this.saveUser)
      this.$emit('onClose')
    },

    loginWithGithub() {
      window.open(
        `${process.env.VUE_APP_HTTP_URL}/auth/google/auth/github`,
        '_blank'
      )
      window.addEventListener('message', this.saveUser)
      this.$emit('onClose')
    },

    saveUser(e) {
      if ('user' in e.data) {
        window.removeEventListener('message', this.saveUser)
        this.$store.dispatch(LOGIN, e.data)
      }
    },
  },
}
</script>

<style scoped></style>
