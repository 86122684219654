<template>
  <div class="user-panel">
    <b-dropdown
      v-if="this.isAuthenticated"
      id="profile-dropdown"
      variant="link"
      toggle-class="text-decoration-none"
      right
      text="Right align"
      no-caret
    >
      <template #button-content>
        <div class="user-panel">
          <p class="user-name">{{ currentUser.firstName }}</p>
          <img
            class="user-image"
            referrerpolicy="no-referrer"
            :src="currentUser.avatar"
            alt="Profile picture"
          />
        </div>
      </template>
      <b-dropdown-item href="#">Profile</b-dropdown-item>
      <b-dropdown-item href="#">Settings</b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="logout">Logout</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { LOGOUT } from '@/store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'user-account-dropdown',
  methods: {
    async logout() {
      await this.$store.dispatch(LOGOUT)
      await this.$router.push({ name: 'dashboard-home' })
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser']),
  },
}
</script>

<style scoped>
.user-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.user-panel .user-image {
  height: 2rem;
  border-radius: 2rem;
  margin-left: 1rem;
}

.user-panel .user-name {
  color: var(--pq-font-color-shade2);
  font-size: 0.85rem;
  margin: 0;
}

@media (max-width: 1199.98px) {
  .user-panel .user-name {
    display: none;
  }
}
</style>
